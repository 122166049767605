
import { defineComponent, PropType, computed, watch } from "vue";
import { multipleSelectionLabel } from "@/modules/palveluhaku/utils/input-utils";
import { Option } from "@/@types/Option";
import { mapHyvinvointialueToMunicipalities } from "../../utils/hyvinvointialueToMunicipalities";

const MUNICIPALITIES = [
    { id: "Akaa", name: "Akaa" },
    { id: "Alajärvi", name: "Alajärvi" },
    { id: "Alavieska", name: "Alavieska" },
    { id: "Alavus", name: "Alavus" },
    { id: "Anjala", name: "Anjala" },
    { id: "Asikkala", name: "Asikkala" },
    { id: "Askola", name: "Askola" },
    { id: "Aura", name: "Aura" },
    { id: "Brändö", name: "Brändö" },
    { id: "Eckerö", name: "Eckerö" },
    { id: "Elimäki", name: "Elimäki" },
    { id: "Enonkoski", name: "Enonkoski" },
    { id: "Enontekiö", name: "Enontekiö" },
    { id: "Espoo", name: "Espoo" },
    { id: "Eura", name: "Eura" },
    { id: "Eurajoki", name: "Eurajoki" },
    { id: "Evijärvi", name: "Evijärvi" },
    { id: "Finström", name: "Finström" },
    { id: "Forssa", name: "Forssa" },
    { id: "Föglö", name: "Föglö" },
    { id: "Geta", name: "Geta" },
    { id: "Haapajärvi", name: "Haapajärvi" },
    { id: "Haapavesi", name: "Haapavesi" },
    { id: "Hailuoto", name: "Hailuoto" },
    { id: "Halsua", name: "Halsua" },
    { id: "Hamina", name: "Hamina" },
    { id: "Hammarland", name: "Hammarland" },
    { id: "Hankasalmi", name: "Hankasalmi" },
    { id: "Hanko", name: "Hanko" },
    { id: "Harjavalta", name: "Harjavalta" },
    { id: "Hartola", name: "Hartola" },
    { id: "Hattula", name: "Hattula" },
    { id: "Hausjärvi", name: "Hausjärvi" },
    { id: "Heinola", name: "Heinola" },
    { id: "Heinävesi", name: "Heinävesi" },
    { id: "Helsinki", name: "Helsinki" },
    { id: "Hirvensalmi", name: "Hirvensalmi" },
    { id: "Hollola", name: "Hollola" },
    { id: "Huittinen", name: "Huittinen" },
    { id: "Humppila", name: "Humppila" },
    { id: "Hyrynsalmi", name: "Hyrynsalmi" },
    { id: "Hyvinkää", name: "Hyvinkää" },
    { id: "Hämeenkyrö", name: "Hämeenkyrö" },
    { id: "Hämeenlinna", name: "Hämeenlinna" },
    { id: "Ii", name: "Ii" },
    { id: "Iisalmi", name: "Iisalmi" },
    { id: "Iitti", name: "Iitti" },
    { id: "Iitti", name: "Iitti" },
    { id: "Ikaalinen", name: "Ikaalinen" },
    { id: "Ilmajoki", name: "Ilmajoki" },
    { id: "Ilomantsi", name: "Ilomantsi" },
    { id: "Imatra", name: "Imatra" },
    { id: "Inari", name: "Inari" },
    { id: "Inkoo", name: "Inkoo" },
    { id: "Isojoki", name: "Isojoki" },
    { id: "Isokyrö", name: "Isokyrö" },
    { id: "Jaala", name: "Jaala" },
    { id: "Janakkala", name: "Janakkala" },
    { id: "Joensuu", name: "Joensuu" },
    { id: "Jokioinen", name: "Jokioinen" },
    { id: "Jomala", name: "Jomala" },
    { id: "Joroinen", name: "Joroinen" },
    { id: "Joutsa", name: "Joutsa" },
    { id: "Juuka", name: "Juuka" },
    { id: "Juupajoki", name: "Juupajoki" },
    { id: "Juva", name: "Juva" },
    { id: "Jyväskylä", name: "Jyväskylä" },
    { id: "Jämijärvi", name: "Jämijärvi" },
    { id: "Jämsä", name: "Jämsä" },
    { id: "Järvenpää", name: "Järvenpää" },
    { id: "Kaarina", name: "Kaarina" },
    { id: "Kaavi", name: "Kaavi" },
    { id: "Kajaani", name: "Kajaani" },
    { id: "Kalajoki", name: "Kalajoki" },
    { id: "Kangasala", name: "Kangasala" },
    { id: "Kangasniemi", name: "Kangasniemi" },
    { id: "Kankaanpää", name: "Kankaanpää" },
    { id: "Kannonkoski", name: "Kannonkoski" },
    { id: "Kannus", name: "Kannus" },
    { id: "Karhula", name: "Karhula" },
    { id: "Karijoki", name: "Karijoki" },
    { id: "Karkkila", name: "Karkkila" },
    { id: "Karstula", name: "Karstula" },
    { id: "Karvia", name: "Karvia" },
    { id: "Kaskinen", name: "Kaskinen" },
    { id: "Kauhajoki", name: "Kauhajoki" },
    { id: "Kauhava", name: "Kauhava" },
    { id: "Kauniainen", name: "Kauniainen" },
    { id: "Kaustinen", name: "Kaustinen" },
    { id: "Keitele", name: "Keitele" },
    { id: "Kemi", name: "Kemi" },
    { id: "Kemijärvi", name: "Kemijärvi" },
    { id: "Keminmaa", name: "Keminmaa" },
    { id: "Kemiönsaari", name: "Kemiönsaari" },
    { id: "Kempele", name: "Kempele" },
    { id: "Kerava", name: "Kerava" },
    { id: "Keuruu", name: "Keuruu" },
    { id: "Kihniö", name: "Kihniö" },
    { id: "Kinnula", name: "Kinnula" },
    { id: "Kirkkonummi", name: "Kirkkonummi" },
    { id: "Kitee", name: "Kitee" },
    { id: "Kittilä", name: "Kittilä" },
    { id: "Kiuruvesi", name: "Kiuruvesi" },
    { id: "Kivijärvi", name: "Kivijärvi" },
    { id: "Kokemäki", name: "Kokemäki" },
    { id: "Kokkola", name: "Kokkola" },
    { id: "Kolari", name: "Kolari" },
    { id: "Konnevesi", name: "Konnevesi" },
    { id: "Kontiolahti", name: "Kontiolahti" },
    { id: "Korsnäs", name: "Korsnäs" },
    { id: "Koski TI", name: "Koski TI" },
    { id: "Kotka", name: "Kotka" },
    { id: "Kouvola", name: "Kouvola" },
    { id: "Kristiinankaupunki", name: "Kristiinankaupunki" },
    { id: "Kruunupyy", name: "Kruunupyy" },
    { id: "Kuhmo", name: "Kuhmo" },
    { id: "Kuhmoinen", name: "Kuhmoinen" },
    { id: "Kumlinge", name: "Kumlinge" },
    { id: "Kuopio", name: "Kuopio" },
    { id: "Kuortane", name: "Kuortane" },
    { id: "Kurikka", name: "Kurikka" },
    { id: "Kustavi", name: "Kustavi" },
    { id: "Kuusamo", name: "Kuusamo" },
    { id: "Kuusankoski", name: "Kuusankoski" },
    { id: "Kymi", name: "Kymi" },
    { id: "Kyyjärvi", name: "Kyyjärvi" },
    { id: "Kärkölä", name: "Kärkölä" },
    { id: "Kärsämäki", name: "Kärsämäki" },
    { id: "Kökar", name: "Kökar" },
    { id: "Lahti", name: "Lahti" },
    { id: "Laihia", name: "Laihia" },
    { id: "Laitiola", name: "Laitiola" },
    { id: "Lapinjärvi", name: "Lapinjärvi" },
    { id: "Lapinlahti", name: "Lapinlahti" },
    { id: "Lappajärvi", name: "Lappajärvi" },
    { id: "Lappeenranta", name: "Lappeenranta" },
    { id: "Lapua", name: "Lapua" },
    { id: "Laukaa", name: "Laukaa" },
    { id: "Lemi", name: "Lemi" },
    { id: "Lemland", name: "Lemland" },
    { id: "Lempäälä", name: "Lempäälä" },
    { id: "Leppävirta", name: "Leppävirta" },
    { id: "Lestijärvi", name: "Lestijärvi" },
    { id: "Lieksa", name: "Lieksa" },
    { id: "Lieto", name: "Lieto" },
    { id: "Liminka", name: "Liminka" },
    { id: "Liperi", name: "Liperi" },
    { id: "Lohja", name: "Lohja" },
    { id: "Loimaa", name: "Loimaa" },
    { id: "Loppi", name: "Loppi" },
    { id: "Loviisa", name: "Loviisa" },
    { id: "Luhanka", name: "Luhanka" },
    { id: "Lumijoki", name: "Lumijoki" },
    { id: "Lumparland", name: "Lumparland" },
    { id: "Luoto", name: "Luoto" },
    { id: "Luumäki", name: "Luumäki" },
    { id: "Maalahti", name: "Maalahti" },
    { id: "Maarianhamina", name: "Maarianhamina" },
    { id: "Marttila", name: "Marttila" },
    { id: "Masku", name: "Masku" },
    { id: "Merijärvi", name: "Merijärvi" },
    { id: "Merikarvia", name: "Merikarvia" },
    { id: "Miehikkälä", name: "Miehikkälä" },
    { id: "Mikkeli", name: "Mikkeli" },
    { id: "Muhos", name: "Muhos" },
    { id: "Multia", name: "Multia" },
    { id: "Muonio", name: "Muonio" },
    { id: "Mustasaari", name: "Mustasaari" },
    { id: "Muurame", name: "Muurame" },
    { id: "Mynämäki", name: "Mynämäki" },
    { id: "Myrskylä", name: "Myrskylä" },
    { id: "Mäntsälä", name: "Mäntsälä" },
    { id: "Mänttä-Vilppula", name: "Mänttä-Vilppula" },
    { id: "Mäntyharju", name: "Mäntyharju" },
    { id: "Naantali", name: "Naantali" },
    { id: "Nakkila", name: "Nakkila" },
    { id: "Nivala", name: "Nivala" },
    { id: "Nokia", name: "Nokia" },
    { id: "Nousiainen", name: "Nousiainen" },
    { id: "Nurmes", name: "Nurmes" },
    { id: "Nurmijärvi", name: "Nurmijärvi" },
    { id: "Närpiö", name: "Närpiö" },
    { id: "Orimattila", name: "Orimattila" },
    { id: "Oripää", name: "Oripää" },
    { id: "Orivesi", name: "Orivesi" },
    { id: "Oulainen", name: "Oulainen" },
    { id: "Oulu", name: "Oulu" },
    { id: "Outokumpu", name: "Outokumpu" },
    { id: "Padasjoki", name: "Padasjoki" },
    { id: "Paimio", name: "Paimio" },
    { id: "Paltamo", name: "Paltamo" },
    { id: "Parainen", name: "Parainen" },
    { id: "Parikkala", name: "Parikkala" },
    { id: "Parkano", name: "Parkano" },
    { id: "Pedersöre", name: "Pedersöre" },
    { id: "Pelkosenniemi", name: "Pelkosenniemi" },
    { id: "Pello", name: "Pello" },
    { id: "Perho", name: "Perho" },
    { id: "Pertunmaa", name: "Pertunmaa" },
    { id: "Petäjävesi", name: "Petäjävesi" },
    { id: "Pieksämäki", name: "Pieksämäki" },
    { id: "Pielavesi", name: "Pielavesi" },
    { id: "Pietarsaari", name: "Pietarsaari" },
    { id: "Pihtipudas", name: "Pihtipudas" },
    { id: "Pirkkala", name: "Pirkkala" },
    { id: "Polvijärvi", name: "Polvijärvi" },
    { id: "Pomarkku", name: "Pomarkku" },
    { id: "Pori", name: "Pori" },
    { id: "Pornainen", name: "Pornainen" },
    { id: "Porvoo", name: "Porvoo" },
    { id: "Posio", name: "Posio" },
    { id: "Pudasjärvi", name: "Pudasjärvi" },
    { id: "Pukkila", name: "Pukkila" },
    { id: "Punkalaidun", name: "Punkalaidun" },
    { id: "Puolanka", name: "Puolanka" },
    { id: "Puumala", name: "Puumala" },
    { id: "Pyhtää", name: "Pyhtää" },
    { id: "Pyhäjoki", name: "Pyhäjoki" },
    { id: "Pyhäjärvi", name: "Pyhäjärvi" },
    { id: "Pyhäntä", name: "Pyhäntä" },
    { id: "Pyhäranta", name: "Pyhäranta" },
    { id: "Pälkäne", name: "Pälkäne" },
    { id: "Pöytyä", name: "Pöytyä" },
    { id: "Raahe", name: "Raahe" },
    { id: "Raasepori", name: "Raasepori" },
    { id: "Raiso", name: "Raiso" },
    { id: "Rantasalmi", name: "Rantasalmi" },
    { id: "Ranua", name: "Ranua" },
    { id: "Rauma", name: "Rauma" },
    { id: "Rautalampi", name: "Rautalampi" },
    { id: "Rautavaara", name: "Rautavaara" },
    { id: "Rautjärvi", name: "Rautjärvi" },
    { id: "Reisjärvi", name: "Reisjärvi" },
    { id: "Riihimäki", name: "Riihimäki" },
    { id: "Ristijärvi", name: "Ristijärvi" },
    { id: "Rovaniemi", name: "Rovaniemi" },
    { id: "Ruokolahti", name: "Ruokolahti" },
    { id: "Rusko", name: "Rusko" },
    { id: "Rääkkylä", name: "Rääkkylä" },
    { id: "Saarijärvi", name: "Saarijärvi" },
    { id: "Salla", name: "Salla" },
    { id: "Salo", name: "Salo" },
    { id: "Saltvik", name: "Saltvik" },
    { id: "Sastamala", name: "Sastamala" },
    { id: "Sauvo", name: "Sauvo" },
    { id: "Savitaipale", name: "Savitaipale" },
    { id: "Savonlinna", name: "Savonlinna" },
    { id: "Savukoski", name: "Savukoski" },
    { id: "Seinäjoki", name: "Seinäjoki" },
    { id: "Sievi", name: "Sievi" },
    { id: "Siikainen", name: "Siikainen" },
    { id: "Siikajoki", name: "Siikajoki" },
    { id: "Siikalatva", name: "Siikalatva" },
    { id: "Siilinjärvi", name: "Siilinjärvi" },
    { id: "Simo", name: "Simo" },
    { id: "Sipoo", name: "Sipoo" },
    { id: "Sippola", name: "Sippola" },
    { id: "Siuntio", name: "Siuntio" },
    { id: "Sodankylä", name: "Sodankylä" },
    { id: "Soini", name: "Soini" },
    { id: "Somero", name: "Somero" },
    { id: "Sonkajärvi", name: "Sonkajärvi" },
    { id: "Sotkamo", name: "Sotkamo" },
    { id: "Sottunga", name: "Sottunga" },
    { id: "Sulkava", name: "Sulkava" },
    { id: "Sund", name: "Sund" },
    { id: "Suomussalmi", name: "Suomussalmi" },
    { id: "Suonenjoki", name: "Suonenjoki" },
    { id: "Sysmä", name: "Sysmä" },
    { id: "Säkylä", name: "Säkylä" },
    { id: "Taipalsaari", name: "Taipalsaari" },
    { id: "Taivalkoski", name: "Taivalkoski" },
    { id: "Taivassalo", name: "Taivassalo" },
    { id: "Tammela", name: "Tammela" },
    { id: "Tampere", name: "Tampere" },
    { id: "Tervo", name: "Tervo" },
    { id: "Tervola", name: "Tervola" },
    { id: "Teuva", name: "Teuva" },
    { id: "Tohmajärvi", name: "Tohmajärvi" },
    { id: "Toholampi", name: "Toholampi" },
    { id: "Toivakka", name: "Toivakka" },
    { id: "Tornio", name: "Tornio" },
    { id: "Turku", name: "Turku" },
    { id: "Tuusniemi", name: "Tuusniemi" },
    { id: "Tuusula", name: "Tuusula" },
    { id: "Tyrnävä", name: "Tyrnävä" },
    { id: "Ulvila", name: "Ulvila" },
    { id: "Urjala", name: "Urjala" },
    { id: "Utajärvi", name: "Utajärvi" },
    { id: "Utsjoki", name: "Utsjoki" },
    { id: "Uurainen", name: "Uurainen" },
    { id: "Uusikaarlepyy", name: "Uusikaarlepyy" },
    { id: "Uusikaupunki", name: "Uusikaupunki" },
    { id: "Vaala", name: "Vaala" },
    { id: "Vaasa", name: "Vaasa" },
    { id: "Valkeakoski", name: "Valkeakoski" },
    { id: "Valkeala", name: "Valkeala" },
    { id: "Vantaa", name: "Vantaa" },
    { id: "Varkaus", name: "Varkaus" },
    { id: "Vehkalahti", name: "Vehkalahti" },
    { id: "Vehmaa", name: "Vehmaa" },
    { id: "Vesanto", name: "Vesanto" },
    { id: "Vesilahti", name: "Vesilahti" },
    { id: "Veteli", name: "Veteli" },
    { id: "Vieremä", name: "Vieremä" },
    { id: "Vihti", name: "Vihti" },
    { id: "Viitasaari", name: "Viitasaari" },
    { id: "Vimpeli", name: "Vimpeli" },
    { id: "Virolahti", name: "Virolahti" },
    { id: "Virrat", name: "Virrat" },
    { id: "Vårdö", name: "Vårdö" },
    { id: "Vöyri", name: "Vöyri" },
    { id: "Ylitornio", name: "Ylitornio" },
    { id: "Ylivieska", name: "Ylivieska" },
    { id: "Ylöjärvi", name: "Ylöjärvi" },
    { id: "Ypäjä", name: "Ypäjä" },
    { id: "Ähtäri", name: "Ähtäri" },
    { id: "Äänekoski", name: "Äänekoski" },
];

export default defineComponent({
    props: {
        modelValue: Object as PropType<Option | null>,
        multiple: Boolean,
        hideIcon: Boolean,
        location: Array,
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const municipality = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });

        const displayValue = computed(() =>
            props.multiple
                ? multipleSelectionLabel(municipality.value as any)
                : (municipality.value as any)?.name
        );

        const location = computed(() => props.location);

        watch(location, (current, prev) => {
            const prevValues = prev?.map((v: any) => v.id);
            const equalValues = current?.every((v: any) => prevValues?.includes(v.id));
            if (!equalValues) {
                municipality.value = null;
            }
        });

        const municipalitiesToShow = computed(() => {
            return props.location && props.location.length
                ? mapHyvinvointialueToMunicipalities(props.location as Option[])
                : MUNICIPALITIES;
        });

        return {
            municipalities: municipalitiesToShow,
            municipality,
            displayValue,
        };
    },
});
