import { Option } from "@/@types/Option";

interface Map {
    [key: string]: Option[];
}

const municipalitiesByLocation: Map = {
    etelakarjala: [
        { id: "Imatra", name: "Imatra" },
        { id: "Lappeenranta", name: "Lappeenranta" },
        { id: "Lemi", name: "Lemi" },
        { id: "Luumäki", name: "Luumäki" },
        { id: "Parikkala", name: "Parikkala" },
        { id: "Rautjärvi", name: "Rautjärvi" },
        { id: "Ruokolahti", name: "Ruokolahti" },
        { id: "Savitaipale", name: "Savitaipale" },
        { id: "Taipalsaari", name: "Taipalsaari" },
    ],
    etelapohjanmaa: [
        { id: "Alajärvi", name: "Alajärvi" },
        { id: "Alavus", name: "Alavus" },
        { id: "Evijärvi", name: "Evijärvi" },
        { id: "Ilmajoki", name: "Ilmajoki" },
        { id: "Isojoki", name: "Isojoki" },
        { id: "Isokyrö", name: "Isokyrö" },
        { id: "Karijoki", name: "Karijoki" },
        { id: "Kauhajoki", name: "Kauhajoki" },
        { id: "Kauhava", name: "Kauhava" },
        { id: "Kuortane", name: "Kuortane" },
        { id: "Kurikka", name: "Kurikka" },
        { id: "Lappajärvi", name: "Lappajärvi" },
        { id: "Lapua", name: "Lapua" },
        { id: "Seinäjoki", name: "Seinäjoki" },
        { id: "Soini", name: "Soini" },
        { id: "Teuva", name: "Teuva" },
        { id: "Vimpeli", name: "Vimpeli" },
        { id: "Ähtäri", name: "Ähtäri" },
    ],
    etelasavo: [
        { id: "Enonkoski", name: "Enonkoski" },
        { id: "Hirvensalmi", name: "Hirvensalmi" },
        { id: "Juva", name: "Juva" },
        { id: "Kangasniemi", name: "Kangasniemi" },
        { id: "Mikkeli", name: "Mikkeli" },
        { id: "Mäntyharju", name: "Mäntyharju" },
        { id: "Pertunmaa", name: "Pertunmaa" },
        { id: "Pieksämäki", name: "Pieksämäki" },
        { id: "Puumala", name: "Puumala" },
        { id: "Rantasalmi", name: "Rantasalmi" },
        { id: "Savonlinna", name: "Savonlinna" },
        { id: "Sulkava", name: "Sulkava" },
    ],
    helsinki: [{ id: "Helsinki", name: "Helsinki" }],
    itauusimaa: [
        { id: "Askola", name: "Askola" },
        { id: "Lapinjärvi", name: "Lapinjärvi" },
        { id: "Loviisa", name: "Loviisa" },
        { id: "Myrskylä", name: "Myrskylä" },
        { id: "Porvoo", name: "Porvoo" },
        { id: "Pukkila", name: "Pukkila" },
        { id: "Sipoo", name: "Sipoo" },
    ],
    kainuu: [
        { id: "Hyrynsalmi", name: "Hyrynsalmi" },
        { id: "Kajaani", name: "Kajaani" },
        { id: "Kuhmo", name: "Kuhmo" },
        { id: "Paltamo", name: "Paltamo" },
        { id: "Puolanka", name: "Puolanka" },
        { id: "Ristijärvi", name: "Ristijärvi" },
        { id: "Sotkamo", name: "Sotkamo" },
        { id: "Suomussalmi", name: "Suomussalmi" },
    ],
    kantahame: [
        { id: "Forssa", name: "Forssa" },
        { id: "Hattula", name: "Hattula" },
        { id: "Hausjärvi", name: "Hausjärvi" },
        { id: "Hämeenlinna", name: "Hämeenlinna" },
        { id: "Humppila", name: "Humppila" },
        { id: "Janakkala", name: "Janakkala" },
        { id: "Jokioinen", name: "Jokioinen" },
        { id: "Loppi", name: "Loppi" },
        { id: "Riihimäki", name: "Riihimäki" },
        { id: "Tammela", name: "Tammela" },
        { id: "Ypäjä", name: "Ypäjä" },
    ],
    keskisuomi: [
        { id: "Hankasalmi", name: "Hankasalmi" },
        { id: "Joutsa", name: "Joutsa" },
        { id: "Jyväskylä", name: "Jyväskylä" },
        { id: "Jämsä", name: "Jämsä" },
        { id: "Kannonkoski", name: "Kannonkoski" },
        { id: "Karstula", name: "Karstula" },
        { id: "Keuruu", name: "Keuruu" },
        { id: "Kinnula", name: "Kinnula" },
        { id: "Kivijärvi", name: "Kivijärvi" },
        { id: "Konnevesi", name: "Konnevesi" },
        { id: "Kyyjärvi", name: "Kyyjärvi" },
        { id: "Laukaa", name: "Laukaa" },
        { id: "Luhanka", name: "Luhanka" },
        { id: "Multia", name: "Multia" },
        { id: "Muurame", name: "Muurame" },
        { id: "Petäjävesi", name: "Petäjävesi" },
        { id: "Pihtipudas", name: "Pihtipudas" },
        { id: "Saarijärvi", name: "Saarijärvi" },
        { id: "Toivakka", name: "Toivakka" },
        { id: "Uurainen", name: "Uurainen" },
        { id: "Viitasaari", name: "Viitasaari" },
        { id: "Äänekoski", name: "Äänekoski" },
    ],
    keskipohjanmaa: [
        { id: "Halsua", name: "Halsua" },
        { id: "Kannus", name: "Kannus" },
        { id: "Kaustinen", name: "Kaustinen" },
        { id: "Kokkola", name: "Kokkola" },
        { id: "Lestijärvi", name: "Lestijärvi" },
        { id: "Perho", name: "Perho" },
        { id: "Toholampi", name: "Toholampi" },
        { id: "Veteli", name: "Veteli" },
    ],
    keskiuusimaa: [
        { id: "Hyvinkää", name: "Hyvinkää" },
        { id: "Järvenpää", name: "Järvenpää" },
        { id: "Nurmijärvi", name: "Nurmijärvi" },
        { id: "Mäntsälä", name: "Mäntsälä" },
        { id: "Tuusula", name: "Tuusula" },
        { id: "Pornainen", name: "Pornainen" },
    ],
    kymenlaakso: [
        { id: "Anjala", name: "Anjala" },
        { id: "Elimäki", name: "Elimäki" },
        { id: "Hamina", name: "Hamina" },
        { id: "Iitti", name: "Iitti" },
        { id: "Jaala", name: "Jaala" },
        { id: "Karhula", name: "Karhula" },
        { id: "Kotka", name: "Kotka" },
        { id: "Kouvola", name: "Kouvola" },
        { id: "Kuusankoski", name: "Kuusankoski" },
        { id: "Kymi", name: "Kymi" },
        { id: "Miehikkälä", name: "Miehikkälä" },
        { id: "Pyhtää", name: "Pyhtää" },
        { id: "Sippola", name: "Sippola" },
        { id: "Valkeala", name: "Valkeala" },
        { id: "Vehkalahti", name: "Vehkalahti" },
        { id: "Virolahti", name: "Virolahti" },
    ],
    lappi: [
        { id: "Enontekiö", name: "Enontekiö" },
        { id: "Inari", name: "Inari" },
        { id: "Kemi", name: "Kemi" },
        { id: "Kemijärvi", name: "Kemijärvi" },
        { id: "Keminmaa", name: "Keminmaa" },
        { id: "Kittilä", name: "Kittilä" },
        { id: "Kolari", name: "Kolari" },
        { id: "Muonio", name: "Muonio" },
        { id: "Pelkosenniemi", name: "Pelkosenniemi" },
        { id: "Pello", name: "Pello" },
        { id: "Posio", name: "Posio" },
        { id: "Ranua", name: "Ranua" },
        { id: "Rovaniemi", name: "Rovaniemi" },
        { id: "Salla", name: "Salla" },
        { id: "Savukoski", name: "Savukoski" },
        { id: "Simo", name: "Simo" },
        { id: "Sodankylä", name: "Sodankylä" },
        { id: "Tervola", name: "Tervola" },
        { id: "Tornio", name: "Tornio" },
        { id: "Utsjoki", name: "Utsjoki" },
        { id: "Ylitornio", name: "Ylitornio" },
    ],
    lansiuusimaa: [
        { id: "Espoo", name: "Espoo" },
        { id: "Hanko", name: "Hanko" },
        { id: "Inkoo", name: "Inkoo" },
        { id: "Karkkila", name: "Karkkila" },
        { id: "Kauniainen", name: "Kauniainen" },
        { id: "Kirkkonummi", name: "Kirkkonummi" },
        { id: "Lohja", name: "Lohja" },
        { id: "Raasepori", name: "Raasepori" },
        { id: "Siuntio", name: "Siuntio" },
        { id: "Vihti", name: "Vihti" },
    ],
    satakunta: [
        { id: "Eura", name: "Eura" },
        { id: "Eurajoki", name: "Eurajoki" },
        { id: "Harjavalta", name: "Harjavalta" },
        { id: "Huittinen", name: "Huittinen" },
        { id: "Jämijärvi", name: "Jämijärvi" },
        { id: "Kankaanpää", name: "Kankaanpää" },
        { id: "Karvia", name: "Karvia" },
        { id: "Kokemäki", name: "Kokemäki" },
        { id: "Merikarvia", name: "Merikarvia" },
        { id: "Nakkila", name: "Nakkila" },
        { id: "Pomarkku", name: "Pomarkku" },
        { id: "Pori", name: "Pori" },
        { id: "Rauma", name: "Rauma" },
        { id: "Siikainen", name: "Siikainen" },
        { id: "Säkylä", name: "Säkylä" },
        { id: "Ulvila", name: "Ulvila" },
    ],
    pirkanmaa: [
        { id: "Akaa", name: "Akaa" },
        { id: "Hämeenkyrö", name: "Hämeenkyrö" },
        { id: "Ikaalinen", name: "Ikaalinen" },
        { id: "Juupajoki", name: "Juupajoki" },
        { id: "Kangasala", name: "Kangasala" },
        { id: "Kihniö", name: "Kihniö" },
        { id: "Kuhmoinen", name: "Kuhmoinen" },
        { id: "Lempäälä", name: "Lempäälä" },
        { id: "Mänttä-Vilppula", name: "Mänttä-Vilppula" },
        { id: "Nokia", name: "Nokia" },
        { id: "Orivesi", name: "Orivesi" },
        { id: "Parkano", name: "Parkano" },
        { id: "Pirkkala", name: "Pirkkala" },
        { id: "Punkalaidun", name: "Punkalaidun" },
        { id: "Pälkäne", name: "Pälkäne" },
        { id: "Sastamala", name: "Sastamala" },
        { id: "Tampere", name: "Tampere" },
        { id: "Urjala", name: "Urjala" },
        { id: "Valkeakoski", name: "Valkeakoski" },
        { id: "Vesilahti", name: "Vesilahti" },
        { id: "Virrat", name: "Virrat" },
        { id: "Ylöjärvi", name: "Ylöjärvi" },
    ],
    pohjanmaa: [
        { id: "Kaskinen", name: "Kaskinen" },
        { id: "Korsnäs", name: "Korsnäs" },
        { id: "Kristiinankaupunki", name: "Kristiinankaupunki" },
        { id: "Kruunupyy", name: "Kruunupyy" },
        { id: "Laihia", name: "Laihia" },
        { id: "Luoto", name: "Luoto" },
        { id: "Maalahti", name: "Maalahti" },
        { id: "Mustasaari", name: "Mustasaari" },
        { id: "Närpiö", name: "Närpiö" },
        { id: "Pedersöre", name: "Pedersöre" },
        { id: "Pietarsaari", name: "Pietarsaari" },
        { id: "Uusikaarlepyy", name: "Uusikaarlepyy" },
        { id: "Vaasa", name: "Vaasa" },
        { id: "Vöyri", name: "Vöyri" },
    ],
    pohjoiskarjala: [
        { id: "Heinävesi", name: "Heinävesi" },
        { id: "Ilomantsi", name: "Ilomantsi" },
        { id: "Joensuu", name: "Joensuu" },
        { id: "Juuka", name: "Juuka" },
        { id: "Kitee", name: "Kitee" },
        { id: "Kontiolahti", name: "Kontiolahti" },
        { id: "Lieksa", name: "Lieksa" },
        { id: "Liperi", name: "Liperi" },
        { id: "Nurmes", name: "Nurmes" },
        { id: "Outokumpu", name: "Outokumpu" },
        { id: "Polvijärvi", name: "Polvijärvi" },
        { id: "Rääkkylä", name: "Rääkkylä" },
        { id: "Tohmajärvi", name: "Tohmajärvi" },
    ],
    pohjoispohjanmaa: [
        { id: "Alavieska", name: "Alavieska" },
        { id: "Haapajärvi", name: "Haapajärvi" },
        { id: "Haapavesi", name: "Haapavesi" },
        { id: "Hailuoto", name: "Hailuoto" },
        { id: "Ii", name: "Ii" },
        { id: "Kalajoki", name: "Kalajoki" },
        { id: "Kempele", name: "Kempele" },
        { id: "Kuusamo", name: "Kuusamo" },
        { id: "Kärsämäki", name: "Kärsämäki" },
        { id: "Liminka", name: "Liminka" },
        { id: "Lumijoki", name: "Lumijoki" },
        { id: "Merijärvi", name: "Merijärvi" },
        { id: "Muhos", name: "Muhos" },
        { id: "Nivala", name: "Nivala" },
        { id: "Oulainen", name: "Oulainen" },
        { id: "Oulu", name: "Oulu" },
        { id: "Pudasjärvi", name: "Pudasjärvi" },
        { id: "Pyhäjoki", name: "Pyhäjoki" },
        { id: "Pyhäjärvi", name: "Pyhäjärvi" },
        { id: "Pyhäntä", name: "Pyhäntä" },
        { id: "Raahe", name: "Raahe" },
        { id: "Reisjärvi", name: "Reisjärvi" },
        { id: "Sievi", name: "Sievi" },
        { id: "Siikajoki", name: "Siikajoki" },
        { id: "Siikalatva", name: "Siikalatva" },
        { id: "Taivalkoski", name: "Taivalkoski" },
        { id: "Tyrnävä", name: "Tyrnävä" },
        { id: "Utajärvi", name: "Utajärvi" },
        { id: "Vaala", name: "Vaala" },
        { id: "Ylivieska", name: "Ylivieska" },
    ],
    pohjoissavo: [
        { id: "Iisalmi", name: "Iisalmi" },
        { id: "Joroinen", name: "Joroinen" },
        { id: "Kaavi", name: "Kaavi" },
        { id: "Keitele", name: "Keitele" },
        { id: "Kiuruvesi", name: "Kiuruvesi" },
        { id: "Kuopio", name: "Kuopio" },
        { id: "Lapinlahti", name: "Lapinlahti" },
        { id: "Leppävirta", name: "Leppävirta" },
        { id: "Pielavesi", name: "Pielavesi" },
        { id: "Rautalampi", name: "Rautalampi" },
        { id: "Rautavaara", name: "Rautavaara" },
        { id: "Siilinjärvi", name: "Siilinjärvi" },
        { id: "Sonkajärvi", name: "Sonkajärvi" },
        { id: "Suonenjoki", name: "Suonenjoki" },
        { id: "Tervo", name: "Tervo" },
        { id: "Tuusniemi", name: "Tuusniemi" },
        { id: "Varkaus", name: "Varkaus" },
        { id: "Vesanto", name: "Vesanto" },
        { id: "Vieremä", name: "Vieremä" },
    ],
    paijathame: [
        { id: "Asikkala", name: "Asikkala" },
        { id: "Hartola", name: "Hartola" },
        { id: "Heinola", name: "Heinola" },
        { id: "Hollola", name: "Hollola" },
        { id: "Iitti", name: "Iitti" },
        { id: "Kärkölä", name: "Kärkölä" },
        { id: "Lahti", name: "Lahti" },
        { id: "Orimattila", name: "Orimattila" },
        { id: "Padasjoki", name: "Padasjoki" },
        { id: "Sysmä", name: "Sysmä" },
    ],
    vantaakerava: [
        { id: "Kerava", name: "Kerava" },
        { id: "Vantaa", name: "Vantaa" },
    ],
    varsinaissuomi: [
        { id: "Aura", name: "Aura" },
        { id: "Kaarina", name: "Kaarina" },
        { id: "Kemiönsaari", name: "Kemiönsaari" },
        { id: "Koski TI", name: "Koski TI" },
        { id: "Kustavi", name: "Kustavi" },
        { id: "Laitiola", name: "Laitiola" },
        { id: "Lieto", name: "Lieto" },
        { id: "Loimaa", name: "Loimaa" },
        { id: "Marttila", name: "Marttila" },
        { id: "Masku", name: "Masku" },
        { id: "Mynämäki", name: "Mynämäki" },
        { id: "Naantali", name: "Naantali" },
        { id: "Nousiainen", name: "Nousiainen" },
        { id: "Oripää", name: "Oripää" },
        { id: "Paimio", name: "Paimio" },
        { id: "Parainen", name: "Parainen" },
        { id: "Pyhäranta", name: "Pyhäranta" },
        { id: "Pöytyä", name: "Pöytyä" },
        { id: "Raiso", name: "Raiso" },
        { id: "Rusko", name: "Rusko" },
        { id: "Salo", name: "Salo" },
        { id: "Sauvo", name: "Sauvo" },
        { id: "Somero", name: "Somero" },
        { id: "Taivassalo", name: "Taivassalo" },
        { id: "Turku", name: "Turku" },
        { id: "Uusikaupunki", name: "Uusikaupunki" },
        { id: "Vehmaa", name: "Vehmaa" },
    ],
    ahvenanmaa: [
        { id: "Brändö", name: "Brändö" },
        { id: "Eckerö", name: "Eckerö" },
        { id: "Finström", name: "Finström" },
        { id: "Föglö", name: "Föglö" },
        { id: "Geta", name: "Geta" },
        { id: "Hammarland", name: "Hammarland" },
        { id: "Jomala", name: "Jomala" },
        { id: "Kumlinge", name: "Kumlinge" },
        { id: "Kökar", name: "Kökar" },
        { id: "Lemland", name: "Lemland" },
        { id: "Lumparland", name: "Lumparland" },
        { id: "Maarianhamina", name: "Maarianhamina" },
        { id: "Saltvik", name: "Saltvik" },
        { id: "Sottunga", name: "Sottunga" },
        { id: "Sund", name: "Sund" },
        { id: "Vårdö", name: "Vårdö" },
    ],
};

export function mapHyvinvointialueToMunicipalities(locations: Option[]): Option[] {
    if (!locations) return [];
    return locations.flatMap((location) => getMunicipalities(location.id));
}

function getMunicipalities(location: string) {
    const m = municipalitiesByLocation[location];
    return m ? m : [];
}
